import Vue from "vue";
import { library } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon, FontAwesomeLayers, FontAwesomeLayersText } from "@fortawesome/vue-fontawesome";
import { faHome, faCalendarDays, faCreditCard, faMartiniGlassCitrus, faCircle, faTimes, faTag, faPeopleRoof, faUsersBetweenLines, faMeteor, faLock, faPersonThroughWindow, faClover, faBook} from "@fortawesome/free-solid-svg-icons";

library.add(faHome, faCalendarDays, faCreditCard, faMartiniGlassCitrus, faCircle, faTimes, faTag, faPeopleRoof, faUsersBetweenLines, faMeteor, 
    faLock, faPersonThroughWindow, faClover, faBook)

Vue.component('fa-icon', FontAwesomeIcon)
Vue.component('fa-layer', FontAwesomeLayers)
Vue.component('fa-layer-text', FontAwesomeLayersText)
Vue.config.productionTip = false