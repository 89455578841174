<template>
    <div>
    <PageLoader/>
    <nav>
        <v-app-bar color="accent" app elevation="4" dense height="75px">
            <v-app-bar-nav-icon @click.stop="drawer = !drawer"></v-app-bar-nav-icon>
            <v-container @click="$router.push('/Home')">
            <h1>OmniSooS</h1>
            </v-container>
            <v-spacer></v-spacer>
            <img @click="$router.push('/Home')" src="../assets/Logo naam ASV Omnivas.png" style="object-fit:contain;height:50px" />
        </v-app-bar>
        <v-navigation-drawer disable-route-watcher app v-model="drawer" temporary>
            <img src="../assets/Omnivas Logo.png" style="object-fit:contain ;height:200px" class="center" />
            <v-container>
                <v-switch :value="darkMode"
                          @change="toggleDarkMode"
                          :label="` ${switchLabel} mode`">
                </v-switch>
            </v-container>
            <h1 class="text-center">OmniSooS Beta</h1>
            <v-divider></v-divider>
            <v-list nav dense>
                <v-list-item-group w>
                    <template v-if="authenticated">
                        <v-list-item v-for="(item, i) in authItems" :key="i" link @click="$router.push({ path: item.route })" @click.stop="drawer = !drawer">
                            <v-list-item-icon class="d-flex justify-center align-center">
                                    <fa-icon :icon="item.icon" style="color:#bc4d1b"  mask="fa-solid fa-circle" transform="shrink-5" size="2x"/>
                            </v-list-item-icon>
                            <v-list-item-content>
                                <v-list-item-title v-text="item.text"></v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>
                    </template>
                    <template v-else>
                        <v-list-item v-for="(item, i) in loggedOutitems" :key="'A' + i" link @click="$router.push({ path: item.route })" @click.stop="drawer = !drawer">
                            <v-list-item-icon>
                                <fa-icon :icon="item.icon" style="color:#bc4d1b"  mask="fa-solid fa-circle" transform="shrink-5" size="2x"/>
                            </v-list-item-icon>
                            <v-list-item-content>
                                <v-list-item-title v-text="item.text"></v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>
                    </template>
                    <template v-if="authenticated">
                        <v-list-item v-for="(item, i) in signOutItem" :key="'B' + i" @click.prevent="signOut" link @click.stop="drawer = !drawer">
                            <v-list-item-icon>
                                <fa-icon :icon="item.icon" style="color:#bc4d1b"  mask="fa-solid fa-circle" transform="shrink-5" size="2x"/>
                            </v-list-item-icon>
                            <v-list-item-content>
                                <v-list-item-title v-text="item.text"></v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>
                    </template>
                </v-list-item-group>
            </v-list>
        </v-navigation-drawer>
    </nav>
</div>
</template>

<script>
    import { mapGetters, mapActions } from 'vuex'
    import PageLoader from '../components/PageLoader.vue';
    export default {
        components: {
            PageLoader
        },

        data: () => ({
            selectedItem: 0,

            authItems: [
                { text: 'Home', icon: 'fa-solid fa-house', route: '/home' },
                { text: 'Agenda', icon: 'fa-solid fa-calendar-days', route: '/Parties' },
                { text: 'OmniPas', icon: 'fa-solid fa-credit-card', route: '/OmniPas' },
                { text: 'De SooS', icon: 'fa-solid fa-martini-glass-citrus', route: '/SooS' },
                { text: 'Commissies', icon: 'fa-solid fa-users-between-lines', route: '/Commissies' },
                { text: 'Disputen', icon: 'fa-solid fa-people-roof', route: '/Disputen' },
                { text: 'OmniFlits', icon: 'fa-solid fa-book', route: '/Omniflits' },
                { text: 'Klaverjassen', icon: 'fa-solid fa-clover', route: '/Jassen' },
                { text: 'Mede Mogelijk Door', icon: 'fa-solid fa-meteor', route: '/MedeMogelijkDoor' },
            ],

            signOutItem: [
                { text: 'Sign out', icon: 'fa-solid fa-person-through-window', route: '/login' },
            ],

            loggedOutitems: [

                { text: 'Login', icon: 'fa-solid fa-lock', route: '/login' },
            ],

            drawer: null,
            darkMode: false,
        }),
        methods: {
            toggleDarkMode: function () {
                this.$vuetify.theme.dark = !this.$vuetify.theme.dark;
                this.darkMode = !this.darkMode;
            },
            ...mapActions({
                signOutAction: 'auth/singOut'
            }),
            signOut() {
                this.signOutAction().then(() => {
                    this.$router.replace({
                        name: 'login'
                    })
                })
            }
        },
        computed: {


            ...mapGetters({
                authenticated: 'auth/authenticated'
            }),

            switchLabel: function () {
                return this.darkMode ? 'light' : 'dark';
            },

        }
    }
</script>


