import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify'
import router from './router'
import axios from 'axios'
import store from './store'
import VueGeolocation from 'vue-browser-geolocation'
import './icons.js'

Vue.use(VueGeolocation)
require('@/store/subscriber')

Vue.prototype.$axios = axios

import * as VueGoogleMaps from 'vue2-google-maps'
Vue.use(VueGoogleMaps, {
  load: {
    key: 'AIzaSyC0UglprhEqzKCai3h04poX5bQxZzOmJgk'
  },
})

Vue.config.productionTip = false

store.dispatch('auth/attempt', localStorage.getItem('token')).then(() => {

  new Vue({
    vuetify,
    router,
    store,
    render: h => h(App)
  }).$mount('#app')
})

