 <template>
    <v-app class="grey lighten-4">

        <!-- <PageLoader/> -->
        <NavBar />
        <v-main>
            <v-card color="highlightedBackground" :max-width="CardSizing" class="ma-auto" flat>
                <router-view name="MapLoader" />
                <router-view name="HomePage" />
                <router-view name="testPage" />
                <router-view name="LoginPage" />
            </v-card>
        </v-main>
    </v-app>
</template>

<script>
    import { mapGetters } from 'vuex'
    import NavBar from './components/NavBar.vue';
    // import PageLoader from './components/PageLoader.vue';

    export default {

        name: "app",
        components: {
            NavBar,
            // PageLoader,

        },

        mounted: function () {
            if (this.authenticated) {
                console.warn("ja");
            } else {
                console.warn("nee");
                this.$router.push("/login")
            }
        },
        computed: {
            ...mapGetters({
                authenticated: 'auth/authenticated'
            }),
            CardSizing() {
                if (this.$vuetify.breakpoint.mobile) {
                    return '100%';
                }
                else {
                    return '75%';
                }
            },
        }
    };
</script>
