import Vue from 'vue'
import VueRouter from 'vue-router'

import store from '@/store'

Vue.use(VueRouter)

const routes = [
    {
        path: '/home',
        name: 'home',
        components: {
            HomePage: () => import('@/views/HomePage.vue'),
        },
        beforeEnter: (to, from, next) => {
            if (!store.getters['auth/authenticated']) {
                return next({
                    name: 'login'
                })
            }

            next()
        }
    },
    {
        path: '/',
        name: '',
        beforeEnter: (to, from, next) => {
            return next({
                name: 'home'
            })
        }
    },
    {
        path: '/login',
        name: 'login',
        components: {
            LoginPage: () => import('@/views/LoginPage.vue'),
        },
        beforeEnter: (to, from, next) => {
            if (store.getters['auth/authenticated']) {
                return next({
                    name: 'map'
                })
            }

            next()
        }
    },
    {
        path: '/OmniPas',
        name: 'OmniPas',
        components: {
            LoginPage: () => import('@/views/OmniPas.vue'),
        },
        beforeEnter: (to, from, next) => {
            if (!store.getters['auth/authenticated']) {
                return next({
                    name: 'login'
                })
            }

            next()
        }
    },    {
        path: '/TestingPage',
        name: 'TestingPage',
        components: {
            LoginPage: () => import('@/views/TestingPage.vue'),
        },
        beforeEnter: (to, from, next) => {
            if (!store.getters['auth/authenticated']) {
                return next({
                    name: 'login'
                })
            }

            next()
        }
    },
    {
        path: '/SooS',
        name: 'SooS',
        components: {
            LoginPage: () => import('@/views/SooSPagina.vue'),
        },
        beforeEnter: (to, from, next) => {
            if (!store.getters['auth/authenticated']) {
                return next({
                    name: 'login'
                })
            }

            next()
        }
    },
    {
        path: '/Commissies',
        name: 'Commissies',
        components: {
            LoginPage: () => import('@/views/CommissiePage.vue'),
        },
        beforeEnter: (to, from, next) => {
            if (!store.getters['auth/authenticated']) {
                return next({
                    name: 'login'
                })
            }

            next()
        }
    },
    {
    path: '/McDonalds',
    name: 'McDonalds',
    components: {
        LoginPage: () => import('@/views/McDonalds.vue'),
    },
    
    beforeEnter: (to, from, next) => {
        if (!store.getters['auth/authenticated']) {
            return next({
                name: 'login'
            })
        }

        next()
    }
    },
    {
        path: '/Disputen',
        name: 'Disputen',
        components: {
            LoginPage: () => import('@/views/DisputenPage.vue'),
        },
        beforeEnter: (to, from, next) => {
            if (!store.getters['auth/authenticated']) {
                return next({
                    name: 'login'
                })
            }

            next()
        }
    },
    {
        path: '/Jassen',
        name: 'Jassen',
        components: {
            LoginPage: () => import('@/views/JassenTelling.vue'),
        },
        beforeEnter: (to, from, next) => {
            if (!store.getters['auth/authenticated']) {
                return next({
                    name: 'login'
                })
            }

            next()
        }
    },
    {
        path: '/Omniflits',
        name: 'Omniflits',
        components: {
            LoginPage: () => import('@/views/OmniFlitsPage.vue'),
        },
        beforeEnter: (to, from, next) => {
            if (!store.getters['auth/authenticated']) {
                return next({
                    name: 'login'
                })
            }

            next()
        }
    },
    {
        path: '/MedeMogelijkDoor',
        name: 'MedeMogelijkDoor',
        components: {
            LoginPage: () => import('@/views/MedeMogelijkDoorPage.vue'),
        },
        beforeEnter: (to, from, next) => {
            if (!store.getters['auth/authenticated']) {
                return next({
                    name: 'login'
                })
            }

            next()
        }
    },
    {
        path: '/Parties',
        name: 'Parties',
        components: {
            LoginPage: () => import('@/views/PartyPage.vue'),
        },
        beforeEnter: (to, from, next) => {
            if (!store.getters['auth/authenticated']) {
                return next({
                    name: 'login'
                })
            }

            next()
        }
    },

    {
        path: '/404',
        name: '404',
        components: {
            LoginPage: () => import('@/views/PageNotFound.vue'),
        },
        beforeEnter: (to, from, next) => {
            if (!store.getters['auth/authenticated']) {
                return next({
                    name: 'login'
                })
            }

            next()
        }
    },

    {
        path: '/:pathMatch(.*)',
        //name: 'PageNotFound',
        //components: {PageNotFound},
        beforeEnter: (to, from, next) => { 
            next('404') 
        }
    },


]

const router = new VueRouter({
    routes
})

export default router
