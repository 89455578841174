//import axios from 'axios'

export default {
    namespaced: true,

    state: {
        token: null,
    },

    getters: {
        authenticated (state) {
            return state.token
        },
    },

    mutations: {
        SET_TOKEN (state, token) {
            state.token = token
        },

    },

    actions: {
        async signIn ({ dispatch }, credentials) {
            //let response = await axios.post('http://Backend.lid.omnivas.nl:1534/api/Auth/login', credentials)
            let response = 1
            credentials = 1
            if (credentials == 1){
                credentials = 1;
            }
            
            return dispatch('attempt', response.data)
        },
        async attempt ({ commit, state }, token) {
            token = 1;
            if (token) {
                commit('SET_TOKEN', token)
            }

            if (!state.token) {
                return ;
            }
            
        },

        singOut({ commit }) {
            commit('SET_TOKEN', null)
        },

    }
}
