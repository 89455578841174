import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';

Vue.use(Vuetify);

export default new Vuetify({
    theme: {
        themes: {
            dark: {
                primary: '#FF9501',
                accent: '#bc4d1b',
                secondary: '#4FC3F7',
                success: '#4CAF50',
                info: '#1976D2',
                warning: '#FB8C00',
                error: '#C62828',
                highlightedBackground: '#121212',
            },
            light: {
                primary: '#FF9501',
                accent: '#bc4d1b',
                secondary: '#4FC3F7',
                success: '#4CAF50',
                info: '#1976D2',
                warning: '#FB8C00',
                error: '#C62828',
                highlightedBackground: '#ffffff',
            },
        },
      },
});
